<template>
  <common-trs-list-pager
    v-model="internalValue"
  />
</template>

<script>
  export default {
    name: 'ListPager',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
  }
</script>
