<template>
  <div>
    <v-row class="no-gutters px-5 mt-4">
      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        size="30"
      />
      <v-spacer />
      <common-trs-btn
        v-if="!notification"
        type="tertiary"
        icon
        @click="$emit('loadData')"
      >
        <v-icon>mdi-refresh</v-icon>
      </common-trs-btn>
    </v-row>

    <v-row class="mt-md-0 mt-lg-0 px-5">
      <v-col
        v-for="item in items"
        :key="item.id"
        cols="12"
        class="pt-md-0 pt-lg-0"
        :class="notification ? 'pa-0' : ''"
      >
        <slot
          name="item"
          :item="item"
        />
      </v-col>
    </v-row>

    <div
      v-if="items.length === 0"
      class="text-body-1 text-center pt-3 pb-8"
    >
      No items
    </div>
    <div
      class="separate-bar mx-2"
    />
    <list-pager
      v-if="!notification"
      class="px-5"
      v-model="internalOptions.pager"
    />
  </div>
</template>

<script>
  import ListPager from './ListPager'

  export default {
    name: 'List',

    components: {
      ListPager,
    },

    props: {
      options: {
        type: Object,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      hideFilter: {
        type: Boolean,
        default: false,
      },
      notification: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
    }),

    computed: {
      internalOptions: {
        get () {
          return this.options
        },
        set (val) {
          this.$emit('update:options', val)
        },
      },
    },

    watch: {
    },

    created () {
    },

    methods: {
      updateItem (id, data) {
        this.$emit('updateItem', id, data)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .separate-bar {
    border-top: 2px solid var(--v-trsBack-base);
  }
</style>
